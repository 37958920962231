// pen

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "Pen Underwriting",
    },
    products: [
      {
        id: "1",
        title: "Property",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "property",
        clickUrl: "/property-insurance",
      },
      {
        id: "2",
        title: "Liability",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "general-liability",
        clickUrl: "/general-liability",
      },
      {
        id: "3",
        title: "Care Liability",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "care-liability",
        clickUrl: "/care-liability",
      },
      {
        id: "4",
        title: "Transport",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "transport",
        clickUrl: "/transport-insurance",
      },
      {
        id: "5",
        title: "Construction",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "construction",
        clickUrl: "/construction-insurance",
      },
      {
        id: "6",
        title: "Financial Lines",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "professional-indemnity",
        clickUrl: "/professional-risks",
      },
    ],
    jobs: [
      {
        title: "Fruit Packing Sheds",
        type: "Property",
      },
      {
        title: "Wine Bar",
        type: "Property",
      },
      {
        title: "Electrical Equipment Wholesaler",
        type: "Property",
      },
      {
        title: "Foam Insulation Manufacturing",
        type: "Property",
      },
      {
        title: "Foam Insulation Manufacturing",
        type: "Property",
      },
      {
        title: "Hotel with attaching bottle shop",
        type: "Property",
      },
      {
        title: "Hardware Wholesalers",
        type: "Property",
      },
      {
        title: "Small Goods Wholesaler",
        type: "Property",
      },
      {
        title: "Hotel in regional town",
        type: "Property",
      },
      {
        title: "Wine Bar",
        type: "Property",
      },
      {
        title: "Engineering Works",
        type: "Property",
      },
      {
        title: "Hotel with short term accommodation",
        type: "Property",
      },
      {
        title: "Hearing Aid Manufacturing",
        type: "Property",
      },
      {
        title: "Drone Storage",
        type: "Property",
      },
      {
        title: "Injection moulding",
        type: "Property",
      },
      {
        title: "Tavern with attaching office business",
        type: "Property",
      },
      {
        title: "Licensed Pub Group",
        type: "Property",
      },
      {
        title: "Dumpling Wholesalers",
        type: "Property",
      },
      {
        title: "Mixed shopping centres with pub tenant",
        type: "Property",
      },
      {
        title: "Nightclub Operator",
        type: "Property",
      },
      {
        title: "Cabinet Maker",
        type: "Property",
      },
      {
        title: "Gin Distillery",
        type: "Property",
      },
      {
        title: "Commercial Printers",
        type: "Property",
      },
      {
        title: "3-4 Star Hotel (accommodation)",
        type: "Property",
      },

      {
        title:
          "Sales and installation of lifts and platforms for commercial and residential use",
        type: "Liability",
      },
      {
        title: "Water Treatment Services, Equipment Sales & Repairs",
        type: "Liability",
      },
      {
        title:
          "Liability for importer, retailer and fitter of tyres including re-grooving",
        type: "Liability",
      },
      {
        title:
          "Motor vehicle engineer, sales Of engine Spare parts, mechanical repairs",
        type: "Liability",
      },
      {
        title:
          "Bottling Plant and Packaging of Alcoholic and Non-Alcoholic drinks",
        type: "Liability",
      },
      {
        title:
          "Sales & Repairs on Vehicles, Machinery, Heavy Trucks And Marine equipment",
        type: "Liability",
      },
      {
        title: "Engineering company servicing the mining sectors",
        type: "Liability",
      },
      {
        title: "Import and wholesale of new automotive spare parts",
        type: "Liability",
      },
      {
        title: "Residential Building Construction",
        type: "Liability",
      },
      {
        title:
          "Large construction operations including design and construct maintenance contractors, project and construction managers",
        type: "Liability",
      },
      {
        title: "Iron Ore Mining Contractors (above ground)",
        type: "Liability",
      },
      {
        title: "Electrical Services, including Solar Works",
        type: "Liability",
      },
      {
        title: "Hotel & Bottle shop group",
        type: "Hospitality",
      },
      {
        title: "Large regional RSL club ",
        type: "Hospitality",
      },
      {
        title: "Hotel with Accommodation",
        type: "Hospitality",
      },
      {
        title: "Licensed hotel, motel & bottle shops",
        type: "Hospitality",
      },
      {
        title: "Backpackers in WA",
        type: "Hospitality",
      },
      {
        title: "Pub and brewery in regional NSW",
        type: "Hospitality",
      },
      {
        title: "Licensed wine bars and restaurant",
        type: "Hospitality",
      },
      {
        title: "Large Turnover Hotel - Single location",
        type: "Hospitality",
      },

      {
        title:
          "Combined Liability for a number of Home and Community Care businesses in VIC & NSW",
        type: "Care",
      },
      {
        title:
          "Retirement Village - Independent Living Units also includes: gymnasium, restaurant and swimming pool",
        type: "Care",
      },
      {
        title: "Disability Care provider including Social Enterprise",
        type: "Care",
      },
      {
        title:
          "Home & Community Care, Accommodation with shared housing in South Australia",
        type: "Care",
      },
      {
        title:
          "Retirement village in VIC Combined Liability for large residential aged care facility",
        type: "Care",
      },
      {
        title: "Disability Care Provider franchisees",
        type: "Care",
      },
      {
        title:
          "Large Not For Profit Aged Care Facilities also providing in home care services residential aged care facility",
        type: "Care",
      },

      {
        title: "Civil engineering, excavation and concreting in Tasmania",
        type: "Construction",
      },
      {
        title:
          "Water and sewage treatment plant construction and maintenance in SA",
        type: "Construction",
      },
      {
        title:
          "Installation of a battery energy storage facility including infrastructure",
        type: "Construction",
      },
      {
        title:
          "Earthmoving contractors, roadworks, labour hire, dry machinery hire and railway freight works in WA",
        type: "Construction",
      },
      {
        title: "Fabrication and installation of structural steel",
        type: "Construction",
      },
      {
        title: "Residential subdivision in NSW",
        type: "Construction",
      },
      {
        title:
          "Construction for large landscaping contractor including irrigation and park remediation",
        type: "Construction",
      },
      {
        title: "Construction for Roads, Subdivisions & minor Civil Contracts",
        type: "Construction",
      },
      {
        title: "Installation and commissioning of a water treatment plant",
        type: "Construction",
      },

      {
        title:
          "A multi-year, Project Specific XOL D&C policy on for a new university building",
        type: "Professional",
      },
      {
        title:
          "XOL - renewable energy risk - design, construction and project management of solar farms",
        type: "Professional",
      },
      {
        title:
          "XOL PI for large Engineering firm involved in Civil Engineering, Mechanical Engineering and Geo-technical Engineering",
        type: "Professional",
      },
      {
        title:
          "Excess PI D&C for complex construction contractor with work including asbestos & demolition",
        type: "Professional",
      },
      {
        title:
          "Excess PI for large Consulting Engineering Firm including Civil, Structural & Electrical",
        type: "Professional",
      },
      {
        title: "XOL D&C for new residential and commercial buildings",
        type: "Professional",
      },
      {
        title: "XOL PI for building inspector of temporary work structures",
        type: "Professional",
      },
      {
        title: "XOL PI for rigging, demolition and building works",
        type: "Professional",
      },

      {
        title: "Fleet for cartage of sand soil and gravel",
        type: "Transport",
      },
      {
        title: "Delivery of trucks under trade plates",
        type: "Transport",
      },
      {
        title: "Fleet for fencing contractor with one large claim",
        type: "Transport",
      },
      {
        title: "General haulage contractor",
        type: "Transport",
      },
      {
        title: "Refrigerated Transport",
        type: "Transport",
      },
      {
        title: "Fleet for vehicle repairer with loan Hire-rental",
        type: "Transport",
      },
      {
        title: "Garbage Contractor",
        type: "Transport",
      },
      {
        title: "Cartage Contractor - Grain",
        type: "Transport",
      },
      {
        title: "Tipping - Sand, Soil & Gravel",
        type: "Transport",
      },
      {
        title: "Fleet for removalist",
        type: "Transport",
      },
      {
        title: "Commercial fleet for commercial cleaning  services",
        type: "Transport",
      },
      {
        title: "Fleet for Hire/ rental operation ",
        type: "Transport",
      },
    ],
    people: [
      {
        name: "Adrian Lyons",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0625",
        mobileUrl: "0398100625",
        phone: "0481 248 181",
        phoneUrl: "0481248181",
        email: "Adrian_Lyons@penunderwriting.com",
        imageUrl: "Adrian-Lyons",
        location: "Melbourne",
      },
      {
        name: "Amelia Bluett",
        division: "Care Liability",
        role: "Senior Underwriter",
        mobile: "03 9810 0605",
        mobileUrl: "0398100605",
        phone: "0439 757 668",
        phoneUrl: "0439757668",
        email: "Amelia_Bluett@penunderwriting.com",
        imageUrl: "Amelia-Bluett",
        location: "Melbourne",
      },
      {
        name: "Ashley Bunn",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "03 9810 0621",
        mobileUrl: "0398100621",
        phone: "0466 689 507",
        phoneUrl: "0466689507",
        email: "Ashley_Bunn@penunderwriting.com",
        imageUrl: "Ashley-Bunn",
        location: "Melbourne",
      },
      {
        name: "Barry Long",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "03 9810 0611",
        mobileUrl: "0398100611",
        phone: "0481 461 178",
        phoneUrl: "0481461178",
        email: "Barry_Long@penunderwriting.com",
        imageUrl: "Barry-Long",
        location: "Melbourne",
      },
      {
        name: "Benedicta Ankrah",
        division: "Hospitality Liability",
        role: "Senior Underwriter",
        mobile: "02 9323 5037",
        mobileUrl: "0293235037",
        phone: "0418 900 310",
        phoneUrl: "0418900310",
        email: "benedicta_ankrah@penunderwriting.com",
        imageUrl: "Benedicta-Ankrah",
        location: "Sydney",
      },
      {
        name: "Corey Macri ",
        division: "Liability",
        role: "Underwriter",
        mobile: "02 9323 5037",
        mobileUrl: "0293235037",
        phone: "0466 948 343",
        phoneUrl: "0466948343",
        email: "corey_macri@penunderwriting.com",
        imageUrl: "Corey-Macri",
        location: "Sydney",
      },
      {
        name: "David Hackforth",
        division: "Construction",
        role: "Underwriting Manager",
        mobile: "02 9323 5019",
        mobileUrl: "0293235019",
        phone: "0408 405 728",
        phoneUrl: "0408405728",
        email: "david_hackforth@penunderwriting.com",
        imageUrl: "David-Hackforth",
        location: "Sydney",
      },
      {
        name: "Dean West",
        division: "Construction",
        role: "National Construction & Engineering Manager",
        mobile: "02 9323 5029",
        mobileUrl: "0293235029",
        phone: "0468 542 032",
        phoneUrl: "0468542032",
        email: "dean_west@penunderwriting.com",
        imageUrl: "Dean-West",
        location: "Sydney",
      },
      {
        name: "Diva Mupnar",
        division: "Property",
        role: "Underwriter",
        mobile: "03 9810 0656",
        mobileUrl: "0398100656",
        phone: "0481 095 173",
        phoneUrl: "0481095173",
        email: "Diva_Mupnar@penunderwriting.com",
        imageUrl: "Diva-Mupnar",
        location: "Melbourne",
      },
      {
        name: "Ed Rooke",
        division: "Liability",
        role: "Technical Manager – Liability",
        mobile: "02 9323 5080",
        mobileUrl: "0293235080",
        phone: "0403 178 668",
        phoneUrl: "0403178668",
        email: "ed_rooke@penunderwriting.com",
        imageUrl: "Ed-Rooke",
        location: "Sydney",
      },
      {
        name: "Ely Romero",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "02 9323 5071",
        mobileUrl: "0293235071",
        phone: "0466 689 779",
        phoneUrl: "0466689779",
        email: "ely_romero@penunderwriting.com",
        imageUrl: "Ely-Romero",
        location: "Sydney",
      },
      {
        name: "Felipe Medina Campo",
        division: "Care Liability",
        role: "Underwriter",
        mobile: "03 9810 0610",
        mobileUrl: "0398100610",
        phone: "0466 537 639",
        phoneUrl: "0466537639",
        email: "felipe_medinacampo@penunderwriting.com",
        imageUrl: "Felipe-Campo",
        location: "Melbourne",
      },
      {
        name: "Francis Lee",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0606",
        mobileUrl: "0398100606",
        phone: "0479 181 446",
        phoneUrl: "0479181446",
        email: "Francis_Lee@penunderwriting.com",
        imageUrl: "Francis-Lee",
        location: "Melbourne",
      },
      {
        name: "Gary Beer",
        division: "Property",
        role: "Underwriter",
        mobile: "02 9323 5015",
        mobileUrl: "0293235015",
        phone: "0481 093 939",
        phoneUrl: "0481093939",
        email: "gary_beer@penunderwriting.com",
        imageUrl: "Gary-Beer",
        location: "Sydney",
      },
      {
        name: "Ian Warmer",
        division: "Construction",
        role: "Senior Underwriter",
        mobile: "02 9323 5039",
        mobileUrl: "0293235039",
        phone: "0418 900 794",
        phoneUrl: "0418900794",
        email: "ian_warmer@penunderwriting.com",
        imageUrl: "Ian-Warmer",
        location: "Sydney",
      },
      {
        name: "James Clay",
        division: "Transport",
        role: "Underwriter",
        mobile: "02 9323 5010",
        mobileUrl: "0293235010",
        phone: "0421 626 261",
        phoneUrl: "0421626261",
        email: "james_clay@penunderwriting.com",
        imageUrl: "James-Clay",
        location: "Sydney",
      },
      {
        name: "Jaimelee Quetua",
        division: "Property",
        role: "Underwriter",
        mobile: "07 3056 1431",
        mobileUrl: "0730561431",
        phone: "0481 395 721",
        phoneUrl: "0481395721",
        email: "jaimelee_quetua@penunderwriting.com",
        imageUrl: "Jaimelee-Quetua",
        location: "Sydney",
      },
      {
        name: "Jack Chia",
        division: "Financial Lines",
        role: "Senior Underwriter",
        mobile: "02 9323 5010",
        mobileUrl: "0293235010",
        phone: "0466 614 106",
        phoneUrl: "0466614106",
        email: "jack_chia@penunderwriting.com",
        imageUrl: "Jack-Chia",
        location: "Sydney",
      },
      {
        name: "Jenny Prendergast",
        division: "Transport",
        role: "Senior Underwriter",
        mobile: "07 3056 1431",
        mobileUrl: "0730561431",
        phone: "0403 870 007",
        phoneUrl: "0403870007",
        email: "Jenny_Prendergast@penunderwriting.com",
        imageUrl: "Jenny-Prendergast",
        location: "Brisbane",
      },
      {
        name: "Jerome Furtado",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "02 9323 5072",
        mobileUrl: "02 9323 5072",
        phone: "0438 692 269",
        phoneUrl: "0438692269",
        email: "jerome_furtado@penunderwriting.com",
        imageUrl: "Jerome-Furtado",
        location: "Sydney",
      },
      {
        name: "Josephine Ho",
        division: "Liability",
        role: "Underwriter",
        mobile: "07 3056 1435",
        mobileUrl: "0730561435",
        phone: "0428 034 951",
        phoneUrl: "0428034951",
        email: "Josephine_Ho@penunderwriting.com",
        imageUrl: "Josephine-Ho",
        location: "Brisbane",
      },
      {
        name: "Karen Taylor",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0613",
        mobileUrl: "0398100613",
        phone: "0403 603 477",
        phoneUrl: "0403603477",
        email: "Karen_Taylor@penunderwriting.com",
        imageUrl: "Karen-Taylor",
        location: "Melbourne",
      },
      {
        name: "Katharine Vermont",
        division: "Property",
        role: "Underwriter",
        mobile: "03 9810 0609",
        mobileUrl: "0398100609",
        phone: "0478 269 525",
        phoneUrl: "0478269525",
        email: "Katharine_Vermont@penunderwriting.com",
        imageUrl: "Katharine-Vermont",
        location: "Melbourne",
      },
      {
        name: "Louise Soutter",
        division: "Financial Lines",
        role: "Underwriting Manager",
        mobile: "07 3056 1406",
        mobileUrl: "0730561406",
        phone: "0481 058 703",
        phoneUrl: "0481058703",
        email: "Louise_Soutter@penunderwriting.com",
        imageUrl: "Louise-Soutter",
        location: "Brisbane",
      },
      {
        name: "Linda Gleave",
        division: "Care Liability",
        role: "Senior Underwriter ",
        mobile: "02 9323 5052",
        mobileUrl: "02 9323 5052",
        phone: "0427 153 228",
        phoneUrl: "0427153228",
        email: "Linda_Gleave@penunderwriting.com",
        imageUrl: "Linda-Gleave",
        location: "Sydney",
      },
      {
        name: "Luke Bartier",
        division: "Construction",
        role: "Assistant Underwriter",
        mobile: "0421 593 934 ",
        mobileUrl: "0421593934 ",
        phone: "0421 593 934 ",
        phoneUrl: "0421593934 ",
        email: "luke_bartier@penunderwriting.com",
        imageUrl: "Luke-Bartier",
        location: "Sydney",
      },
      {
        name: "Mary-Anne Turner",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0624",
        mobileUrl: "0398100624",
        phone: "0481 477 076",
        phoneUrl: "0481477076",
        email: "MaryAnne_Turner@penunderwriting.com",
        imageUrl: "Mary-Anne-Turner",
        location: "Melbourne",
      },
      {
        name: "Maryanne Stanton",
        division: "Property",
        role: "Underwriter",
        mobile: "03 9810 0619",
        mobileUrl: "0398100619",
        phone: "0435 399 769",
        phoneUrl: "0435399769",
        email: "Maryanne_Stanton@penunderwriting.com",
        imageUrl: "Maryanne-Stanton",
        location: "Melbourne",
      },
      {
        name: "Maz McLennan",
        division: "Property",
        role: "Underwriter",
        mobile: "07 3056 1407",
        mobileUrl: "0730561407",
        phone: "0419 512 894",
        phoneUrl: "0419512894",
        email: "Maz_McLennan@penunderwriting.com",
        imageUrl: "Maz-McLennan",
        location: "Brisbane",
      },
      {
        name: "Michael Kennedy",
        division: "Hospitality Liability",
        role: "Underwriter",
        mobile: "02 9323 5024",
        mobileUrl: "0293235024",
        phone: "0403 258 780",
        phoneUrl: "00403258780",
        email: "Michael_Kennedy@penunderwriting.com",
        imageUrl: "Michael-Kennedy",
        location: "Sydney",
      },
      {
        name: "Nick Best",
        division: "Property",
        role: "Team Manager",
        mobile: "03 9810 0608",
        mobileUrl: "0398100608",
        phone: "0479 174 406",
        phoneUrl: "0479174406",
        email: "Nick_Best@penunderwriting.com",
        imageUrl: "Nick-Best",
        location: "Melbourne",
      },
      {
        name: "Patricia Karasova",
        division: "Liability",
        role: "Underwriter",
        mobile: "02 9323 5020",
        mobileUrl: "0293235020",
        phone: "0479 171 158",
        phoneUrl: "0479171158",
        email: "patricia_karasova@penunderwriting.com",
        imageUrl: "Patricia-Karasova",
        location: "Sydney",
      },
      {
        name: "Paul Behm",
        division: "Liability",
        role: "Team Manager",
        mobile: "02 9323 5020",
        mobileUrl: "0293235020",
        phone: "0414 950 122",
        phoneUrl: "0414950122",
        email: "Paul_Behm@penunderwriting.com",
        imageUrl: "Paul-Behm",
        location: "Sydney",
      },
      {
        name: "Rebecca Taylor",
        division: "Financial Lines",
        role: "Assistant Underwriter",
        mobile: "",
        mobileUrl: "",
        phone: "",
        phoneUrl: "",
        email: "rebecca_taylor1@penunderwriting.com",
        imageUrl: "Rebecca-Taylor",
        location: "Brisbane",
      },
      {
        name: "Rob Haward",
        division: "Liability",
        role: "Senior Underwritier",
        mobile: "07 3056 1450",
        mobileUrl: "0730561450",
        phone: "0488 413 231",
        phoneUrl: "0488413231",
        email: "Rob_Haward@penunderwriting.com",
        imageUrl: "Rob-Haward",
        location: "Brisbane",
      },
      {
        name: "Sarah Gerdes",
        division: "Hospitality Liability",
        role: "Senior Underwriter",
        mobile: "02 9323 5017",
        mobileUrl: "0293235017",
        phone: "0403 952 181",
        phoneUrl: "0403952181",
        email: "Sarah_Gerdes@penunderwriting.com",
        imageUrl: "Sarah-Gerdes",
        location: "Sydney",
      },
      {
        name: "Sally Ing",
        division: "Property",
        role: " Underwriter ",
        mobile: "02 9323 5018",
        mobileUrl: "0293235018",
        phone: "0481 466 280",
        phoneUrl: "0481466280",
        email: "Sally_Ing@penunderwriting.com",
        imageUrl: "Sally-Ing",
        location: "Sydney",
      },
      {
        name: "Srashtee Lalwani",
        division: "Transport",
        role: "Underwriter",
        mobile: "02 9323 5014",
        mobileUrl: "0293235014",
        phone: "0481 094 939",
        phoneUrl: "0481094939",
        email: "Srashtee_Lalwani@penunderwriting.com",
        imageUrl: "Srashtee-Lalwani",
        location: "Sydney",
      },
      {
        name: "Styve Tebbutt",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "07 3056 1440",
        mobileUrl: "0730561440",
        phone: "0466 170 601",
        phoneUrl: "0466170601",
        email: "Styve_Tebbutt@penunderwriting.com",
        imageUrl: "Styve-Tebbutt",
        location: "Brisbane",
      },
      {
        name: "Tim Moore",
        division: "Transport",
        role: "Team Manager",
        mobile: "03 9810 0615",
        mobileUrl: "0398100615",
        phone: "0417 565 636",
        phoneUrl: "0417565636",
        email: "Tim_Moore@penunderwriting.com",
        imageUrl: "Tim-Moore",
        location: "Melbourne",
      },
      {
        name: "Tanya Ommi",
        division: "Property",
        role: "Underwriter",
        mobile: "02 9323 5016",
        mobileUrl: "0293235016",
        phone: "0403 969 960",
        phoneUrl: "0403969960",
        email: "Tanya_Ommi@penunderwriting.com",
        imageUrl: "Tanya-Ommi",
        location: "Sydney",
      },
      {
        name: "Vanessa MacDonald",
        division: "Financial Lines",
        role: "Senior Underwriter",
        mobile: "02 9323 5024",
        mobileUrl: "0293235024",
        phone: "0440 100 777",
        phoneUrl: "0440100777",
        email: "vanessa_macdonald@penunderwriting.com",
        imageUrl: "Vanessa-MacDonald",
        location: "Melbourne",
      },
      {
        name: "Vanessa Zoricic",
        division: "Transport",
        role: "Underwriter",
        mobile: "02 9323 5024",
        mobileUrl: "0293235024",
        phone: "0468 655 750",
        phoneUrl: "0468655750",
        email: "vanessa_zoricic@penunderwriting.com",
        imageUrl: "Vanessa-Zoricic",
        location: "Melbourne",
      },
      {
        name: "Yulia Basuki",
        division: "Care Liability",
        role: "Underwriter",
        mobile: "02 9323 5084",
        mobileUrl: "0293235084",
        phone: "0478 264 754",
        phoneUrl: "0478264754",
        email: "Yulia_Basuki@penunderwriting.com",
        imageUrl: "Yulia-Basuki",
        location: "Sydney",
      },
    ],
    docs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "property",
        url: "Building_Your_Super_Factsheet_2023-2024.pdf",
      },
      {
        title: "Join the BGC fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "construction",
        url: "BGC-New_Employee_Pack_October_2023.pdf",
      },
      {
        title:
          "Demolition_and_Asbestos_Removal_Liability_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Demolition_and_Asbestos_Removal_Liability_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "Errors_and_Omissions_Extension_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Errors_and_Omissions_Extension_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "General_Public_and_Products_Liability_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/General_Public_and_Products_Liability_v0121_Fillable_Form.pdf",
      },
      {
        title: "Mining_Risks_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Mining_Risks_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "Products_Liability_Proposal_v0121_Fillable_Forms",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Products_Liability_Proposal_v0121_Fillable_Forms.pdf",
      },
      {
        title: "Pyrotechnics_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Pyrotechnics_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "Rail_Contractors_Liability_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Rail_Contractors_Liability_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "Stock_Feed_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Stock_Feed_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "Umbrella_Liability_Proposal_v0121_Fillable_Form",
        description: "proposal",
        type: "liability",
        url: "proposal/general_liability/Umbrella_Liability_Proposal_v0121_Fillable_Form.pdf",
      },
      {
        title: "IOOF Online - Registration",
        description: "Find out how to register online with IOOF.",
        type: "care liability",
        url: "IOOF-Registration-form.pdf",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "financial lines",
        url: "IOOF-Employer-Super-PDS-PLA-23705-50670-0323.pdf",
      },
      {
        title: "Finding your Lost Super",
        description:
          "Complete this form to authorise IOOF to search for your super.",
        type: "transport",
        url: "IOOF-SuperMatchform.pdf",
      },
    ],
  },
  analytics: {
    gacode: "G-VSVR12ML7P",
    gaprod: "G-VSVR12ML7P",
  },
};
